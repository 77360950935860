import logo from "../../assets/blue-fox logo.png";
import text from "../../assets/BFlogoTextWHT.png";
import LinkItem from "./linkItem";
import {TfiMenu} from "react-icons/tfi";
import * as React from "react";
import {Link} from "react-router-dom";

//https://react-icons.github.io/react-icons/icons/sl/
//https://react-icons.github.io/react-icons/icons/tfi/

function TabBar(tabBarContent) {
    if (!tabBarContent)
        tabBarContent = {};

    let tabLinks = tabBarContent.tabLinks;

    if (!tabLinks)
        tabLinks = [];

    tabLinks = tabLinks.filter(row => row.label);

    tabLinks
        .filter(link => link.href.endsWith("/*"))
        .forEach(link => link.href = link.href.replace("/*", ""))

    return (
        <nav className="navbar bg-primary text-primary-content">
            <div className="navbar-start">
                <div className="drawer bg-primary text-primary-content lg:hidden z-50">
                    <input id="nav-drawer" type="checkbox" className="drawer-toggle"/>
                    <label htmlFor="nav-drawer" className="btn btn-ghost lg:hidden drawer-button text-xl">
                        <TfiMenu/>
                    </label>
                    <div className="drawer-side">
                        <label htmlFor="nav-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
                        <ul className="menu p-4 w-80 min-h-full bg-primary">
                            {GetSmallScreenLinks(tabLinks, (tabBarContent.href ? tabBarContent.href : ""))}
                        </ul>
                    </div>
                </div>
                <a
                    className={"btn btn-ghost flex h-0  pt-0  pb-0"}
                    href={(tabBarContent.href ? tabBarContent.href : "") +
                        (tabLinks && tabLinks.length > 0 ? tabLinks[0].href : "")}>
                    <img className={"h-full"} src={logo} alt="logo"/>
                    <img className={"h-full"} src={text} alt="logo"/>
                </a>
            </div>
            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1">
                    {getFullScreenLinks(tabLinks, (tabBarContent.href ? tabBarContent.href : ""))}
                </ul>
            </div>
        </nav>
    );
}

function getFullScreenLinks(tabLinks, baseTab) {
    let updatedBaseTab = baseTab && baseTab !== "" && !baseTab.endsWith('/')
        ? baseTab + '/' : baseTab;

    return tabLinks
        .filter(link => link.label !== null)
        .map(link => {
            if (link.innerTabLinks && link.innerTabLinks.filter(link => link.label !== null).length > 0) {
                return <li key={link.id}>
                    <details>
                        <summary>{link.label}</summary>
                        <ul className="menu menu-horizontal p-2 bg-primary text-primary-content min-w-max">
                            {GetSmallScreenLinks(link.innerTabLinks, (updatedBaseTab ? updatedBaseTab : "") + link.href)}
                        </ul>
                    </details>
                </li>
            }

            return <LinkItem key={link.id}
                             href={(updatedBaseTab ? updatedBaseTab : "") + link.href}>{link.label}</LinkItem>
        });
}

export function GetSmallScreenLinks(tabLinks, baseTab) {
    let updatedBaseTab = baseTab && baseTab !== "" && !baseTab.endsWith('/')
        ? baseTab + '/' : baseTab;

    return tabLinks
        .filter(link => link.label !== null)
        .map(link => {
            if (link.innerTabLinks && link.innerTabLinks.filter(link => link.label !== null).length > 0) {
                return (<>
                    <li key={link.id}>
                        <Link to={(updatedBaseTab ? updatedBaseTab : "") + link.href}>{link.label}</Link>
                        <ul className="p-2 text-primary-content">
                            {GetSmallScreenLinks(link.innerTabLinks, (updatedBaseTab ? updatedBaseTab : "") + link.href)}
                        </ul>
                    </li>
                </>)
            }

            return <LinkItem key={link.id}
                             href={(updatedBaseTab ? updatedBaseTab : "") + link.href}>{link.label}</LinkItem>
        });
}

export default TabBar;
