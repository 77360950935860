import logo from "../assets/blue-fox logo.png";
import text from "../assets/BFlogoText.png";
import React, {useState} from 'react'
import slide1 from "../assets/Screenshot 2024-08-15 221215.png";
import slide2 from "../assets/Picture2.png";
import slide3 from "../assets/Picture3.png";
import slide4 from "../assets/Picture4.png";
import slide5 from "../assets/Picture5.png";
import slide6 from "../assets/Picture6.png";
import slide7 from "../assets/Picture7.png";

export function Home() {
    const [slide, setSlide] = useState(1);
    const [slideImage, setSlideImage] = useState(slide2);

    setTimeout(() => {
        updateSlide(slide, setSlide, setSlideImage, 1, 7, 2);
    }, 1500);

    return (
        <div className={"bg-base-100 max-h-full"}>
            <section className={"pt-10 sm:pt-2 md:pt-18"}>
                <div className={"mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0 lg:flex"}>
                    <div className={"m-auto"}>
                        <div className={"bg-primary justify-center items-center rounded-full " +
                            "w-40 h-40 md:w-60 md:h-60 lg:w-80 lg:h-80 flex m-auto"}>
                            <img className={"m-auto w-44 h-40 md:w-56 md:h-60 lg:w-72 lg:h-80 "
                            } src={logo} alt="logo"/>
                        </div>
                    </div>
                    <div className={"m-auto"}>
                        <div className={"justify-center items-center h-40 flex m-auto"}>
                            <img className={"m-auto h-full "
                            } src={text} alt="logo"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className={"pt-28 sm:pt-10 md:pt-16"}>
                <div className={"md:flex"}>
                    <div className={"mx-auto hidden md:block carousel-item relative"}>
                        <img className={"m-auto max-w-[45vw]"} src={slideImage} alt="Demo"/>
                    </div>
                    <div className={"mx-auto max-w-[80vw] md:max-w-[65vw]"}>
                        <h2 className={"text-center text-2xl font-bold sm:text-3xl lg:text-4xl"}>
                            Building what we can invision</h2>
                        <p className={"mt-12 text-lg text-base-600 sm:text-xl"}>
                            A collection of projects tools and products that we would like to share.
                        </p>
                        <br/>
                        <b>
                            <a className={"btn btn-primary"}
                               href={"/products/api-development-tools/toolKit"}>
                               More details</a>
                        </b>.
                    </div>
                </div>
            </section>
            <section className={"bg-base-100 h-10 pt-28 sm:pt-10 md:pt-16"}>
            </section>
        </div>
);
}

function updateSlide(slide, setSlide, setSlideImage, increment, max, min) {
    let slideNumber = slide + increment;

    if (increment > 0 && slide + increment > max) {
        slideNumber = min;
    }
    if (increment < 0 && slide + increment < max) {
        slideNumber = min;
    }
    setSlide(slideNumber);

    switch (slideNumber) {
        case 1:
            setSlideImage(slide1);
            break;
        case 2:
            setSlideImage(slide2);
            break;
        case 3:
            setSlideImage(slide3);
            break;
        case 4:
            setSlideImage(slide4);
            break;
        case 5:
            setSlideImage(slide5);
            break;
        case 6:
            setSlideImage(slide6);
            break;
        case 7:
            setSlideImage(slide7);
            break;
        default:
            setSlideImage(slide2);
            break;
    }
}
