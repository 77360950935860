import React from 'react'

export function Feedback() {

    return (
        <div className={"bg-base-100 h-[100vh]]"}>
            <section className={"pt-28 h-[80vh] sm:pt-10 md:pt-16"}>
                <h2 className={"text-center text-2xl font-bold sm:text-3xl lg:text-4xl"}>Feedback</h2>
                <iframe
                    title="Demo Feedback"
                    height="100%" width="100%"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSdIU5AOJ8myvwEN1WSR1cgRirmMcC9ZTKfFGDLY6wJv3THrZg/viewform?embedded=true">Loading…
                </iframe>
            </section>
            <section className={"pt-28 sm:pt-10 md:pt-16 bg-base-100"}>
            </section>
        </div>
    );
}