import slide1 from "../assets/Screenshot 2024-08-15 221215.png";
import slide2 from "../assets/Picture2.png";
import slide3 from "../assets/Picture3.png";
import slide4 from "../assets/Picture4.png";
import slide5 from "../assets/Picture5.png";
import slide6 from "../assets/Picture6.png";
import slide7 from "../assets/Picture7.png";

import React, {useState} from 'react'
import testTogether from "../assets/test together.png";

export function ToolKit() {
    const [slide, setSlide] = useState(1);
    const [slideImage, setSlideImage] = useState(slide2);

    return (
        <div className={"bg-base-100 overflow-y-auto"}>
            <section className={"pt-28 sm:pt-10 md:pt-16"}>
                <div className={"md:flex"}>
                    <div className={"mx-auto max-w-[80vw] md:max-w-[65vw]"}>
                        <h2 className={"text-center text-2xl font-bold sm:text-3xl lg:text-4xl"}>
                            Build and test together</h2>
                        <img className={"mx-auto block md:hidden"} src={testTogether} alt="test Together"/>
                        <p className={"mt-12 text-lg text-base-600 sm:text-xl"}>
                            Test your internal and external Api's with the Blue-Fox Solutions toolkit.
                            <br/>
                            We Aim to help you build, test and monitor your Api's quicker and better.
                        </p>
                    </div>
                    <div className={"mx-auto hidden md:block"}>
                        <img className={"m-auto max-w-[20vw]"} src={testTogether} alt="test Together"/>
                    </div>
                </div>
            </section>
            <section className={"pt-28 sm:pt-10 md:pt-16"}>
                <div className={"md:flex"}>
                    <div className={"mx-auto hidden md:block carousel-item relative"}>
                        <img className={"m-auto max-w-[45vw]"} src={slideImage} alt="Demo"/>
                        <div
                            className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                            <span className="btn btn-circle"
                                  onClick={() => updateSlide(slide, setSlide, setSlideImage, -1, 2, 7)}>❮</span>
                            <span className="btn btn-circle "
                                  onClick={() => updateSlide(slide, setSlide, setSlideImage, 1, 7, 2)}>❯</span>
                        </div>
                    </div>
                    <div className={"mx-auto max-w-[80vw] md:max-w-[45vw]"}>
                        <h2 className={"text-center text-2xl font-bold sm:text-3xl lg:text-4xl"}>API Development tool
                            kit</h2>
                        <ul className={"mt-12 text-lg text-base-600 sm:text-xl"}>
                            <li>
                                <span className={"font-bold"}>Restful</span>
                                : a direct interface between your developers and the API’s you use
                            </li>
                            <li>
                                <span className={"font-bold"}>Faker</span>
                                : when your provider API isn’t ready, fake them to keep your team moving.
                            </li>
                            <li>
                                <span className={"font-bold"}>Open API editor</span>
                                : see what your API will look like to those you want to serve long before
                                you go live
                            </li>
                            <li>
                                <span className={"font-bold"}>Markdown editor</span>
                                : see how your documentation will look Realtime as you type.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}

function updateSlide(slide, setSlide, setSlideImage, increment, max, min) {
    let slideNumber = slide + increment;

    if (increment > 0 && slide + increment > max) {
        slideNumber = min;
    }
    if (increment < 0 && slide + increment < max) {
        slideNumber = min;
    }
    setSlide(slideNumber);

    switch (slideNumber) {
        case 1:
            setSlideImage(slide1);
            break;
        case 2:
            setSlideImage(slide2);
            break;
        case 3:
            setSlideImage(slide3);
            break;
        case 4:
            setSlideImage(slide4);
            break;
        case 5:
            setSlideImage(slide5);
            break;
        case 6:
            setSlideImage(slide6);
            break;
        case 7:
            setSlideImage(slide7);
            break;
        default:
            setSlideImage(slide2);
            break;
    }
}