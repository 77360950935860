import * as React from "react";
import "../output.css";
import TabBar from "../component/tabBar/TabBar";
import {Theme} from "react-daisyui";
import {Home} from "./Home";
import {Route, Routes} from "react-router-dom";
import {ChangeLog} from "./ChangeLog";
import {Feedback} from "./Feedback";
import {ToolKit} from "./ToolKit";
import {Demo} from "./Demo";

function App() {
    let routes = [{
        id: 0,
        label: "Home",
        href: "/",
        element: <Home/>
    }, {
        id: 1,
        label: "Products",
        href: "/products",
        innerTabLinks: [
            {
                id: 2,
                label: "Api Tools",
                href: "api-development-tools",
                innerTabLinks: [
                    {
                        id: 3,
                        label: "Tool Kit",
                        href: "toolKit",
                        element: <ToolKit/>
                    },
                    {
                        id: 4,
                        label: "Demo",
                        href: "demo",
                        element: <Demo/>
                    },
                    {
                        id: 5,
                        label: "Change log",
                        href: "change-log",
                        element: <ChangeLog/>
                    },
                    {
                        id: 5,
                        label: "Feedback",
                        href: "feedback",
                        element: <Feedback/>
                    }
                ]
            },
        ]
    }];

    return (
        <div className={"min-h-screen max-h-screen h-screen" +
            "min-w-screen max-w-screen w-screen" +
            " overflow-scroll overflow-y-auto overflow-x-auto"}>
            <Theme
                className={"min-h-screen max-h-screen h-screen z-2" +
                    " min-w-screen max-w-screen w-screen" +
                    " bg-base-100 text-base-content"}>
                <div className={"h-full"}>
                    <TabBar href={""} tabLinks={routes}/>
                    <Routes>
                        {getRoutes(routes, true)}
                    </Routes>
                </div>
            </Theme>
        </div>
    );
}

function getRoutes(tabLink, addIndex) {
    let routes = [];
    if (!tabLink || tabLink.length === 0) {
        return routes;
    }

    let index = addIndex ? <Route key={-tabLink.at(0).id} index element={tabLink.at(0).element}/> : null;
    if (index) {
        routes = [...routes, index]
    }
    routes = [...routes,
        tabLink
            .map(link => <Route key={link.id} path={link.href} element={link.element}>
                {getRoutes(link.innerTabLinks, addIndex)}
            </Route>)];

    return routes;
}


export default App;
